.root {
  top: 58px;
}

.arrowUp {
  position: absolute;
  right: 12px;
  top: -7px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid white;
}
