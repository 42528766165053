.react-datepicker * {
  outline: none;
}

.react-datepicker {
  font-family: inherit !important;
  border: unset !important;
  border-radius: 24px;
}

.customPicker .react-datepicker__header {
  text-align: center;
  background-color: unset;
  border: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding-top: 8px;
  position: relative;
}

.customPicker .react-datepicker__current-month,
.customPicker .react-datepicker-time__header,
.customPicker .react-datepicker-year-header {
  font-weight: normal !important;
  font-size: 1rem;
  margin-bottom: 12px;
}

.customPicker
  .react-datepicker__time-container
  .customPicker
  .react-datepicker__time
  .customPicker
  .react-datepicker__time-box
  ul.customPicker
  .react-datepicker__time-list
  li.customPicker
  .react-datepicker__time-list-item--selected {
  background: linear-gradient(45deg, #7bc77f 3.57%, #46d0d9 100%);
  color: white;
  font-weight: bold;
}

.customPicker
  .react-datepicker__time-container
  .customPicker
  .react-datepicker__time
  .customPicker
  .react-datepicker__time-box
  ul.customPicker
  .react-datepicker__time-list
  li.customPicker
  .react-datepicker__time-list-item--selected:hover {
  background: linear-gradient(45deg, #7bc77f 3.57%, #46d0d9 100%);
}

.customPicker .react-datepicker__day--weekend {
  color: #8a6cff !important;
}

.customPicker .react-datepicker__day-name,
.customPicker .react-datepicker__day,
.customPicker .react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  margin: 2px 0;
}

.customPicker .react-datepicker__day-name {
  color: #9aa5ad !important;
  margin: 0;
  line-height: 22px;
  height: 22px;
}

.customPicker .react-datepicker__day {
  font-size: 16px;
}

.customPicker .react-datepicker__month--selected,
.customPicker .react-datepicker__month--in-selecting-range,
.customPicker .react-datepicker__month--in-range,
.customPicker .react-datepicker__quarter--selected,
.customPicker .react-datepicker__quarter--in-selecting-range,
.customPicker .react-datepicker__quarter--in-range {
  border-radius: 0;
  background: linear-gradient(45deg, #7bc77f 3.57%, #46d0d9 100%);
  color: #000000;
}

.customPicker .react-datepicker__day--range-start,
.customPicker .react-datepicker__day--range-end {
  background: linear-gradient(45deg, #7bc77f 3.57%, #46d0d9 100%) !important;
  border-radius: 50px !important;
  color: #fff !important;
}

.customPicker .react-datepicker__month--selected:hover,
.customPicker .react-datepicker__month--in-selecting-range:hover,
.customPicker .react-datepicker__month--in-range:hover,
.customPicker .react-datepicker__quarter--selected:hover,
.customPicker .react-datepicker__quarter--in-selecting-range:hover,
.customPicker .react-datepicker__quarter--in-range:hover {
  background-color: #e7fdf4;
}

.customPicker .react-datepicker__day--selected,
.customPicker .react-datepicker__day--in-selecting-range,
.customPicker .react-datepicker__day--in-range,
.customPicker .react-datepicker__month-text--selected,
.customPicker .react-datepicker__month-text--in-selecting-range,
.customPicker .react-datepicker__month-text--in-range,
.customPicker .react-datepicker__quarter-text--selected,
.customPicker .react-datepicker__quarter-text--in-selecting-range,
.customPicker .react-datepicker__quarter-text--in-range,
.customPicker .react-datepicker__year-text--selected,
.customPicker .react-datepicker__year-text--in-selecting-range,
.customPicker .react-datepicker__year-text--in-range {
  border-radius: 0;
  background-color: #e7fdf4;
  color: #000000;
}

.customPicker .react-datepicker__day--selected:hover,
.customPicker .react-datepicker__day--in-selecting-range:hover,
.customPicker .react-datepicker__day--in-range:hover,
.customPicker .react-datepicker__month-text--selected:hover,
.customPicker .react-datepicker__month-text--in-selecting-range:hover,
.customPicker .react-datepicker__month-text--in-range:hover,
.customPicker .react-datepicker__quarter-text--selected:hover,
.customPicker .react-datepicker__quarter-text--in-selecting-range:hover,
.customPicker .react-datepicker__quarter-text--in-range:hover,
.customPicker .react-datepicker__year-text--selected:hover,
.customPicker .react-datepicker__year-text--in-selecting-range:hover,
.customPicker .react-datepicker__year-text--in-range:hover {
  background: linear-gradient(45deg, #7bc77f 3.57%, #46d0d9 100%) !important;
  opacity: 0.65;
  color: #fff;
  border-radius: 50px !important;
}

.customPicker .react-datepicker__day--keyboard-selected,
.customPicker .react-datepicker__month-text--keyboard-selected,
.customPicker .react-datepicker__quarter-text--keyboard-selected,
.customPicker .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.customPicker .react-datepicker__day:hover,
.customPicker .react-datepicker__month-text:hover,
.customPicker .react-datepicker__quarter-text:hover,
.customPicker .react-datepicker__year-text:hover {
  border-radius: 50px;
  background-color: #e7fdf4;
  color: #000;
}

.customPicker .react-datepicker__day--keyboard-selected:hover,
.customPicker .react-datepicker__month-text--keyboard-selected:hover,
.customPicker .react-datepicker__quarter-text--keyboard-selected:hover,
.customPicker .react-datepicker__year-text--keyboard-selected:hover {
  background: linear-gradient(45deg, #7bc77f 3.57%, #46d0d9 100%);
}

.customPicker .react-datepicker__day--in-selecting-range,
.customPicker .react-datepicker__month-text--in-selecting-range,
.customPicker .react-datepicker__quarter-text--in-selecting-range,
.customPicker .react-datepicker__year-text--in-selecting-range {
  background-color: #e7fdf4;
  color: #000000 !important;
}

.customPicker
  .react-datepicker__month--selecting-range
  .customPicker
  .react-datepicker__day--in-range,
.customPicker
  .react-datepicker__month--selecting-range
  .customPicker
  .react-datepicker__month-text--in-range,
.customPicker
  .react-datepicker__month--selecting-range
  .customPicker
  .react-datepicker__quarter-text--in-range,
.customPicker
  .react-datepicker__month--selecting-range
  .customPicker
  .react-datepicker__year-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.customPicker .react-datepicker__month-text.customPicker .react-datepicker__month--selected:hover,
.customPicker .react-datepicker__month-text.customPicker .react-datepicker__month--in-range:hover,
.customPicker .react-datepicker__month-text.customPicker .react-datepicker__quarter--selected:hover,
.customPicker .react-datepicker__month-text.customPicker .react-datepicker__quarter--in-range:hover,
.customPicker .react-datepicker__quarter-text.customPicker .react-datepicker__month--selected:hover,
.customPicker .react-datepicker__quarter-text.customPicker .react-datepicker__month--in-range:hover,
.customPicker
  .react-datepicker__quarter-text.customPicker
  .react-datepicker__quarter--selected:hover,
.customPicker
  .react-datepicker__quarter-text.customPicker
  .react-datepicker__quarter--in-range:hover {
  background: linear-gradient(45deg, #7bc77f 3.57%, #46d0d9 100%);
}

.customPicker .react-datepicker__close-icon::after {
  background: linear-gradient(45deg, #7bc77f 3.57%, #46d0d9 100%);
}

.customPicker .react-datepicker__day--disabled,
.customPicker .react-datepicker__month-text--disabled,
.customPicker .react-datepicker__quarter-text--disabled,
.customPicker .react-datepicker__year-text--disabled {
  color: #ccc !important;
}
