.customPickerField {
  padding: 8px 2px;
  background: #ffffff;
  box-shadow: 0px 30px 80px rgba(52, 54, 100, 0.1);
  border-radius: 16px;
}

.customPickerField .react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 25px;
}

.customPickerField .react-datepicker__header {
  background-color: unset;
  border: unset;
}

.react-datepicker__month {
  margin: 0 16px;
}

.customPickerField .react-datepicker__month .react-datepicker__month-text,
.customPickerField .react-datepicker__month .react-datepicker__quarter-text {
  width: 50%;
  font-size: 20px;
  line-height: 32px;
  padding: 2px 8px;
  border-radius: 12px;
  margin: 0;
  text-align: left;
}

.customPickerField .react-datepicker__day--weekend {
  color: #8a6cff;
}

.customPickerField .react-datepicker__month--selected,
.customPickerField .react-datepicker__month-text--keyboard-selected,
.customPickerField .react-datepicker__day--selected {
  background: linear-gradient(45deg, #7bc77f 3.57%, #46d0d9 100%);
  color: #fff;
}

.customPickerField .react-datepicker__day {
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  border-radius: 50px;
}

.customPickerField .react-datepicker__day-name {
  color: #9aa5ad;
  width: 36px;
  line-height: 22px;
  height: 22px;
  font-size: 16px;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #e7fdf4;
}

.customPickerField .react-datepicker__day-name,
.customPickerField .react-datepicker__day,
.customPickerField .react-datepicker__time-name {
  margin: 2px 0;
}
